
.appointment-wrapper{
    display: flex;
    flex-flow: row wrap;
    justify-content: flex-start;
  
    .appointment-item{
        cursor: pointer;
      display: flex;
      width: 70px;
      border: 1px solid lightgrey;
      border-radius: 30px;
      text-align: center;
      height: 40px;
      vertical-align: middle;
      justify-content: center;
      align-items: center;
      margin:10px  10px 0 0 ;
      color:#000;
      &:hover{
        color: #3C88B3;
      }
    }
    
  }
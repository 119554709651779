




#session-detail {
  .item-header-gradient {
    position: absolute;
    z-index: -1;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    //background: url(../img/shadow.png) no-repeat bottom center;
    background-size: cover;
  }

  .session-type {
    font-size: 0.9rem;
  }

  .session-title {
    font-weight: 500;
  }

  .session-location {
    padding-top: 10px;
    font-size: 0.9rem;

    span {
      color: inherit;
    }
  }

  .session-outline {
    ul {
      list-style: disc;
      margin-bottom: 20px;
    }

    ul li {
      color: #293039;
      font-size: 15px;
      margin-left: 20px;
      white-space: normal;
    }

    ol {
      list-style: decimal;
      margin-bottom: 20px;
    }

    ol li {
      color: #293039;
      font-size: 15px;
      margin-left: 20px;
      white-space: normal;
    }
  }

  .item.item-tabs {
    padding: 0;
    min-height: 30px;
    color: #222;
    font-weight: 500;
    display: flex;

    .item-tab {
      text-align: center;
      padding: 8px;
      width: 50%;
      cursor: pointer;
      border-bottom: solid 1px #ddd;

      &.active {
        box-shadow: 0 1px 3px rgba(0, 0, 0, 0.3);
        background-color: #f5f5f5;
      }
    }
  }

  .item-divider-title {
    font-weight: 500;
    color: #444;
    font-size: 1rem;
  }

  .item-divider {
    background-color: #fff;
    font-weight: 400;
    padding: 20px 15px 3px 15px;
    font-size: 1.2rem;
  }
}

$timeslot-height: 180px;
$timeslot-width: 240px;
$roomslot-width: 240px;
$timeheader-width : 80px;
$roomheader-height: 60px;

.meeting-sessionGridComponent {
  position: relative;
  width: 100%;
  height: calc(100vh - 160px);
  overflow-x: scroll;
  overflow-y: scroll;

  .meeting-session-grid-component {
    display: flex;
    position: relative;
  }

  .meeting-corner-item {
    position: fixed;
    display: flex;
    left: 0;
    top: 44px;
    z-index: 200;
    background-color: #f5f5f5;
    min-width: $timeheader-width;
    width: $timeheader-width;
    height: $roomheader-height;
    border-right: solid 1px rgba(0, 0, 0, 0.125);
    border-bottom: solid 2px rgba(0, 0, 0, 0.125);
    border-top: solid 2px rgba(0, 0, 0, 0.125);
    justify-content: center;
    align-items: center;

    i {
      position: absolute;
      height: 60px;
      width: 100%;
      margin: 0 auto;
      font-size: 3rem;
      opacity: 0.2;
      line-height: 69px;
      text-align: center;
      z-index: -1;
    }

    .meeting-corner-item-text {
      font-size: 0.7rem;
      font-weight: 700;
    }
  }

 

  .consultant-item-iphone {
    top: 43px !important;
  }

  .meeting-room-items {
    > .meeting-room-item:first-child {
      min-width: $timeheader-width;
      width: $timeheader-width;
      border-top: solid 2px rgba(0, 0, 0, 0.125);
      border-bottom: solid 2px rgba(0, 0, 0, 0.125);
    }
  }

  .meeting-room-item {
    background-color: #f5f5f5;
    min-width: $roomslot-width;
    width: $timeslot-width;
    height: $roomheader-height;
    display: flex;
    padding: 0.25rem 0.5rem;
    justify-content: center;
    align-items: center;
    font-size: 0.9rem;
    text-align: center;
    font-weight: 700;
    border-right: solid 1px rgba(0, 0, 0, 0.125);
    border-bottom: solid 1px rgba(0, 0, 0, 0.125);
    a{
      text-decoration:none;
    }
  }
  

  .meeting-hours-grid {
    font-size: 0.7rem;
    font-weight: 700;
  }

  .meeting-hour-items-row {
    display: flex;
    position: relative;
    height: $timeslot-height;
  }

  .meeting-hour-header-item {
    width: $timeheader-width;
    min-width: $timeheader-width;
    height: $timeslot-height;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #f5f5f5;
    border-right: solid 1px rgba(0, 0, 0, 0.125);
    border-bottom: solid 2px rgba(0, 0, 0, 0.125);
    border-top: solid 2px rgba(0, 0, 0, 0.125);
    font-size: 0.9rem;
    font-weight: 700;
  }

  .meeting-hour-item {
    border-right: solid 1px rgba(0, 0, 0, 0.125);
    border-bottom: solid 1px rgba(0, 0, 0, 0.125);
    width: $timeslot-width;
    min-width: $timeslot-width;
    box-shadow: 0 0 0 rgba(0, 0, 0, 0.12), 0 0 0 rgba(0, 0, 0, 0.24);
    transition: all 0.3s cubic-bezier(0.25, 0.8, 0.25, 1);
    z-index: 5;
    background-color: #fff;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .meeting-session-item {
    position: absolute;
    height: $timeslot-height;
    top: 0;
    z-index: 20;
    display: flex;
    flex-direction: column;
    padding: 5px;
    text-decoration:none;
    justify-content: space-between;

    .break-title {
      font-size: 0.75rem;
      font-weight: 500;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      width: 100%;
      min-width: 0;

      &.rotated {
        width: auto;
        min-width: auto;
        transform: rotate(90deg);
      }
    }

    &.is-break {
      top: $timeslot-height;
      z-index: 10;
      display: flex;
      justify-content: center;
      align-items: center;
    }
.meeting-si-content {
  border: 1px solid #EBECEE;
  border-left: 8px solid #EBECEE;
  border-radius: 4px;
  background:#fff;
  box-shadow: 0 10px 40px rgba(0, 0, 0, 0.08);
  height: 100%;
  overflow: hidden;
  cursor: pointer;

}
    .meeting-si-code {
      font-size: 0.8rem;
      background: #858585;
      font-weight: 600;
      padding: 3px 2px;
      line-height: 1;
      border-radius: 2px;
      color: #fff;
      width: max-content;
      margin-bottom: 5px;
    }

    .meeting-si-time {
      position:absolute;
      top:8px;
      right:8px;
      font-size: 0.8rem;
      color: #858585;
      font-weight: 600;
      margin: 0;
      line-height: 1.1;
    }

    .meeting-si-title {
      color: #858585;
      padding-left:5px;
      font-weight: 600;
      margin: 0;
      font-size: 12px;
    }

    .meeting-filler {
      background-color: #f5f5f5;
      flex: 1;
      height: $timeslot-height;
      border-top: solid 2px rgba(0, 0, 0, 0.125);
      border-bottom: solid 2px rgba(0, 0, 0, 0.125);
    }
  }
}

.meeting-sessionGridComponent.rooms-vertical {
  .meeting-hours-grid {
    > .meeting-hour-items-row:first-child {
      display: flex;
      position: sticky;
      position:-webkit-sticky;
      top: 0;
      align-self: flex-start;
      height: auto;
      z-index: 100;
    }
  }

  .meeting-room-items {
    position: sticky;
    position:-webkit-sticky;
    left: 0;
    align-self: flex-start;
    height: auto;
    z-index: 100;
  }
}

.meeting-sessionGridComponent.rooms-horizontal {
  .meeting-session-grid-component {
    flex-direction: column;
  }

  .meeting-room-items {
    display: flex;
    position: sticky;
    position:-webkit-sticky;
    top: 0;
    align-self: flex-start;
    height: auto;
    z-index: 100;
  }

  .meeting-meeting-room-item {
    border-top: solid 2px rgba(0, 0, 0, 0.125);
    border-bottom: solid 2px rgba(0, 0, 0, 0.125);
  }

  .meeting-hour-items-row {
    height: auto;
    flex-direction: column;
  }

  .meeting-hours-grid {
    display: flex;

    > .meeting-hour-items-row:first-child {
      display: flex;
      position: sticky;
      position:-webkit-sticky;
      left: 0;
      align-self: flex-start;
      height: auto;
      z-index: 100;
    }
  }

  .meeting-hour-header-item {
    border-right: solid 1px rgba(0, 0, 0, 0.125);
    border-bottom: solid 1px rgba(0, 0, 0, 0.125);
    border-top: solid 0px rgba(0, 0, 0, 0.125);
  }

  .meeting-hour-item {
    height: $timeslot-height;
    min-height: $timeslot-height;
  }

  .meeting-session-item {
   
    width: $timeslot-width;
    &.is-break {
      margin-left: $timeslot-width;
    }

    .break-title {
      transform: rotate(0deg);

      &.rotated {
        transform: rotate(0deg);
      }
    }
  }

}

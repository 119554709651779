.no-results-message{
    width: 100%;
    text-align: center;
    margin-top: 20px;
    color: rgb(156, 155, 155);
}


.cancel-meeting-button{
    background: red;
    color: red;

}

.busy-buttons{
    font-family: 'Raleway', "Helvetica Neue", Helvetica, Arial, sans-serif;
    min-width: 100%;
    max-width: 100%;
    background: rgba(253, 253, 253, 0.8);
    color: red;
   
    align-items: center;
    justify-content: center;
    border: rgb(219, 219, 219);
    padding: 5px;
    border-radius: 5px;
    z-index: 11;
    
    
    padding: 8px;
    
    font-size: 15px;
}

.no-requests {
    color: rgb(191, 191, 191);
    text-align: center;
    padding: 20px;
  } 

  .requester-email{
      text-decoration: none !important;
      color: #3d5a59 !important;
  }

.toolbar-title {
    text-align: center;
    --background: rgb(251, 251, 251) !important;
    color: rgb(76, 76, 76);
    font-family: Avenir,Helvetica,Arial,sans-serif;
}

.request-table-title {
font-family: Avenir,Helvetica,Arial,sans-serif;
color: rgb(76, 76, 76);
font-weight: bold;
text-align: center;
padding: 5px;
border-style: solid;
border-width: 2px;
border-color:rgb(191, 191, 191);
border-right-style: none;
border-left-style: none;
border-top-style: none;
} 

.meeting-link{
    display: inline-block; 
    position: absolute;
    top:140px;
    right: 15px;
    font-size: 12px;
    padding-right: 10px;
    padding-left: 10px;
    padding-top: 5px;
    padding-bottom: 5px;
    border-radius: 25px;
    box-shadow: 2px 2px 1px rgb(183, 182, 182);
    text-decoration: none;
    color: white
}

.list-meeting-link{
    display: inline-block; 
    position: absolute;
    top:140px;
    right: 15px;
    font-size: 12px;
    padding-right: 10px;
    padding-left: 10px;
    padding-top: 5px;
    padding-bottom: 5px;
    border-radius: 25px;
    box-shadow: 2px 2px 1px rgb(183, 182, 182);
    text-decoration: none;
    color: white;       
    background: #24189b
}


.disabled-icon{
    display: inline-block;
    vertical-align: middle;
}

.disabled-tag{
    margin: 0;
    padding-left: 5px;
    padding-right: 5px;
    position:absolute;
    left:10px;
    top: 5px;
    font-size: small;
    color: rgb(255, 255, 255);
    background: rgb(227, 2, 2);
    }
    
    .parent-row{
        position: relative
    }

.request-table-title.subject{
    border-color:rgb(231, 231, 231);
}

.request-table-title.mobile{
    padding: 10px;
    border-width: 0px;
    border-style: solid;
border-width: 1px;
border-color:rgb(242, 242, 242);
border-right-style: none;
border-left-style: none;
border-top-style: none;
}



.request-row{
    max-height: 80px;
font-family: Avenir,Helvetica,Arial,sans-serif;
text-align: center;
color: rgb(76, 76, 76);

border-style: solid;
border-width: 1px;
border-color:rgb(242, 242, 242);
border-right-style: none;
border-left-style: none;
border-top-style: none;
}

.request-row-phone{
    padding: 10px;
    font-family: Avenir,Helvetica,Arial,sans-serif;
    color: rgb(76, 76, 76);

    border-style: solid;
    border-width: 1px;
    border-color:rgb(242, 242, 242);
    border-right-style: none;
    border-left-style: none;
    border-top-style: none;
    }

.request-text{
    padding: 10px;
    max-height: 50px;
    overflow:hidden;
    }


.request-icon{
    transform: scale(1.5);
    margin-left: 20px;
}

.request-col{
    min-width: 20%;
    max-width: 20%;
}

.request-text-disabled{
    padding: 10px;
    color:rgb(174, 174, 174);
}

.disabled-text{
    color:rgb(174, 174, 174);
}

.modal-request-table-title {
    font-family: Avenir,Helvetica,Arial,sans-serif;
    color: rgb(76, 76, 76);
    font-weight: bold;
    text-align: center;
    padding: 5px;
    border-style: solid;
    border-width: 2px;
    border-color:rgb(191, 191, 191);
    border-right-style: none;
    border-left-style: none;
    border-top-style: none;
    text-align: left;
    } 

.modal-request-titles{
    font-family: Avenir,Helvetica,Arial,sans-serif;
    font-weight: bold;
    color: rgb(76, 76, 76);
    padding: 10px;
    border-style: solid;
    border-width: 1px;
    border-color:rgb(242, 242, 242);
    border-right-style: none;
    border-left-style: none;
    border-top-style: none;
}

.modal-phone-fl{
    min-width: 50%;
    max-width: 50%;
}
.modal-phone-e{
    width: 100%;
    min-width: 100%;
    max-width: 100%;
    text-align: center !important;
}

.modal-request-text{
    font-family: Avenir,Helvetica,Arial,sans-serif;
    text-align: left;
    color: rgb(76, 76, 76);
    padding: 10px;
    border-style: solid;
    border-width: 1px;
    border-color:rgb(242, 242, 242);
    border-right-style: none;
    border-left-style: none;
    border-top-style: none;
}
.modal-consultants-text{
    font-family: Avenir,Helvetica,Arial,sans-serif;
    text-align: center;
    color: rgb(76, 76, 76);
    padding: 10px;
    border-style: solid;
    border-width: 1px;
    border-color:rgb(242, 242, 242);
    border-right-style: none;
    border-left-style: none;
    border-top-style: none;
}

.button-disabled{
    background: #93939592 !important;
    border-color: #c4c4c592 !important;
}
.button-disabled:hover{
    filter: brightness(100%) !important;
}

.requests-buttons{
    font-family: 'Raleway', "Helvetica Neue", Helvetica, Arial, sans-serif;
    min-width: 100%;
    max-width: 100%;
    background: white;
    color: #3d5a59;
   
    align-items: center;
    justify-content: center;
    border: 1px solid #3d5a59;
    padding: 5px;
    border-radius: 25px;
    z-index: 11;
    
    
    padding: 8px;
    
    font-size: 18px;
    box-shadow: 0px 5px 8px rgba(0, 0, 0, 0.1);
}

.requests-buttons:hover{
    filter: brightness(85%);
  }

 .small-request-button{
     min-width: 50px !important;
     width: 50px !important;
     max-width: 50px !important;
     margin-right: 10px;
 }

.requests-accept-button{
    color: white;
    background: #3d5a59;
}



.request-info-button{
    font-family: 'Raleway', "Helvetica Neue", Helvetica, Arial, sans-serif;
    min-width: 280px;
    max-width: 280px;
    color: white;
    background: #3d5a59;
   
    align-items: center;
    justify-content: center;
    border: 1px solid #3d5a59;
    padding: 5px;
    border-radius: 25px;
    z-index: 11;
    
    
    padding: 8px;
    margin-left: 40px !important;
    margin-right: 40px !important;
    
    font-size: 18px;


}

.request-info-button:hover{
    filter: brightness(85%);
  }

.create-consultant-button{
    font-family: 'Raleway', "Helvetica Neue", Helvetica, Arial, sans-serif;
    min-width: 280px;
    max-width: 280px;
    background: white;
    color: #3d5a59;
   
    align-items: center;
    justify-content: center;
    border: 1px solid #3d5a59;
    padding: 5px;
    border-radius: 25px;
    z-index: 11;
    
    
    padding: 8px;
    //margin-left: 40px !important;
    //margin-right: 40px !important;
    
    font-size: 18px;
    box-shadow: 0px 5px 8px rgba(0, 0, 0, 0.1);
  }
  
  .create-consultant-button:hover{
    background-color: #3d5a59;
    color: white;
  }

.edit-consultants-button{
    font-family: 'Raleway', "Helvetica Neue", Helvetica, Arial, sans-serif;
    min-width: 280px;
    max-width: 280px;
    background: white;
    color: #3d5a59;
    margin-bottom: 10px;
    align-items: center;
    justify-content: center;
    border: 1px solid #3d5a59;
    padding: 5px;
    border-radius: 25px;
    z-index: 11;
    
    
    padding: 8px;
    margin-left: 40px !important;
    margin-right: 40px !important;
    
    font-size: 18px;
    box-shadow: 0px 5px 8px rgba(0, 0, 0, 0.1);
  
    position: fixed;
    bottom:   10%;
    z-index: 7;
    left: 50%;
    margin-left: -140px !important;
  }
  
  .edit-consultants-button:hover{
    background-color: #3d5a59;
    color: white;
  }
  

.wideModal
    {
        .modal-wrapper 
        {
            width: 90% !important;
        }
    }
    

input[type="time"], textarea {
    border-radius: 25px;
    padding: 5px;
    background-color : #ffffff; 
    border: 0.5px solid rgb(201, 201, 201);
    color: black;
    
    }
input[type="text"], input[type="password"] {
    border-radius: 25px;
    padding: 10px;
    background-color : #ffffff; 
    border: 0.5px solid rgb(201, 201, 201);
    color: black;
}     
    
input[type="time"]::-webkit-calendar-picker-indicator {
    filter: invert(1);
}

input[type="number"], textarea {
    border-radius: 25px;
    padding: 6px;
    background-color : #ffffff; 
    border: 0.5px solid rgb(201, 201, 201);
    color: black;
    
    background-color : #ffffff; 
    border: 0.5px solid rgb(201, 201, 201);
    color: black;
    
    }

.disabled-consultants-text{
    font-family: Avenir,Helvetica,Arial,sans-serif;
    text-align: center;
    color: rgb(181, 181, 181);
    padding: 10px;
    border-style: solid;
    border-width: 1px;
    border-color:rgb(242, 242, 242);
    border-right-style: none;
    border-left-style: none;
    border-top-style: none;
}

.tooltip {
    position: relative;
    display: inline-block;
    font-family: Avenir,Helvetica,Arial,sans-serif;
    color: rgb(173, 171, 171);
    margin-top: 10px;
    cursor: help;
  }
  
  .tooltip .tooltiptext {
    visibility: hidden;
    width: 300px;
    background-color: rgb(183, 183, 183);
    color: #fff;
    text-align: center;
    padding: 5px 0;
    border-radius: 6px;
   
    position: absolute;
    z-index: 1;
  }
  
  .tooltip:hover .tooltiptext {
    visibility: visible;
    cursor: help;
  }

@media (max-width:768px) and  (orientation:portrait) {
    .busy-buttons{
               
        font-size: 10px;
    }
  
.request-info-button{
    min-width: 100%;
    max-width: 100%;
    margin: 0 !important;

    border-radius: 2px !important;
}

    .wideModal{
        .modal-wrapper {
            width: 100% !important;
        }
    }
}

@media (max-width:768px) and  (orientation:landscape) {

    .request-info-button{
        min-width: 100%;
        max-width: 100%;
        margin: 0 !important;
    }

    .request-col{
        min-width: 20%;
        max-width: 20%;
    }
    
    .wideModal{
        .modal-wrapper {
            width: 100% !important;
        }
    }
}
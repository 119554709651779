.cbrowser-title{
text-align: center;
margin-top: 50px;
margin-bottom: 100px;
color: rgb(95, 94, 93);
}

.cbrowser-table{
    width: 100%;
    text-align: center;
    color: rgb(95, 94, 93);
    }

.cbrowser-row{
margin-top: 20px;
}